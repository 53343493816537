@import 'variables';
@import 'mixins';

.document-action {
    .button-group {
        display: flex;
        margin: 20px 0;

        button {
            font-size: 14px;
            font-weight: 550;
            &.edit {
                background-color: #1d4289;
                &:hover {
                    background-color: rgba(#1d4289, 0.7);
                }
                &:disabled {
                    border: 1px solid #c4c6c4;
                    color: #c4c6c4;
                    cursor: not-allowed;
                    opacity: 0.8;
                    &:hover {
                        background-color: inherit;
                    }
                }
            }
            &.approve {
                background-color: $green;
                &:hover {
                    background-color: rgba($green, 0.7);
                }
            }
            &.reject {
                background-color: $red;
                &:hover {
                    background-color: rgba($red, 0.7);
                }
            }
            &.forward {
                background-color: #1d4289;
                &:hover {
                    background-color: rgba(#1d4289, 0.7);
                }
            }
            &.ad-hoc {
                background-color: #0099ef;
                &:hover {
                    background-color: rgba(#0099ef, 0.7);
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }

    @include respond-below(sm) {
        .button-group {
            flex-wrap: wrap;
            gap: 10px;
            button {
                align-items: center;
                flex: 1 1;
                flex-basis: 90px;
                margin: 0;
                padding: 1em;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.action__modal {
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 12px;
    position: absolute;
    width: 500px;
    @include align-center;
    & > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 16px;

        h1 {
            font-size: 24px;
        }
        span {
            color: #c4c4c4;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
        }

        textarea {
            border-radius: 8px;
            box-sizing: border-box;
            font-family: $font-family;
            margin-top: 8px;
            padding: 12px;
            resize: none;
            width: 100%;
        }
        .comment-section {
            margin-top: 20px;
        }
        button {
            margin-top: 12px;
        }
    }

    .revise-modal {
        h4 {
            margin-bottom: 12px;
        }
        @include tabs(100px);

        .custom-version-inputs {
            display: flex;
            justify-content: center;
            position: relative;
            gap: 10px;
            margin: 5px 0;


            input {
                width: 20%;
            }

            div {
                p {
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }
}

.add_approval_modal,
.forward_doc_modal {
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 32px;
    position: absolute;
    width: 500px;
    @include align-center;

    .search-wrapper {
        display: flex;
        flex-direction: column;
        & > .note {
            font-family: Enphase Visuelt;
            font-size: 11px;
            padding: 4px 0;
        }
    }

    .button-grp {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        button {
            &:last-child {
                margin-right: 0;
            }
            font-size: 14px;
            font-weight: 550;
        }
    }
}
